<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-plant 
              class="none-bottom" 
              type="search" 
              name="plantCd" 
              v-model="searchParam.plantCd"  />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- 기간 -->
            <c-datepicker
              :range="true"
              label="LBLPERIOD"
              type="month"
              name="period"
              defaultStart="-12M"
              defaultEnd="1M"
              v-model="period"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <!-- 발생부서 -->
            <c-dept
              type="search"
              :isFirstValue="false"
              name="occurrenceDeptCd"
              label="LBL0002302"
              v-model="searchParam.occurrenceDeptCd"
            ></c-dept>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <!-- 재해장소 -->
            <c-text
              name="occurrenceLocation"
              label="재해장소"
              v-model="searchParam.occurrenceLocation"
            ></c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <!-- 근속년수 -->
            <c-text
              type="number"
              suffix="년 이상"
              name="longevity"
              label="근속년수"
              v-model="searchParam.longevity"
            ></c-text>
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="padding-top:5px !important;">
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="plantChange" />
          </div> -->
        </template>
      </c-search-box>
      <div class="row">
        <div class="float-right gridbtntop" style="margin-left:auto;">
          <q-btn-group outline >
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="plantChange" />
          </q-btn-group>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <apexchart 
                class="accidentChart"
                ref="yearOcuurChart" 
                height="350" 
                type="bar"
                :options="yearOcuurChart.chartOptions" 
                :series="yearOcuurChart.series"></apexchart>
            </div>
            <div class="col-6">
              <apexchart 
                class="accidentChart"
                ref="seventDayOccurChart" 
                height="350" 
                type="bar"
                :options="seventDayOccurChart.chartOptions" 
                :series="seventDayOccurChart.series"></apexchart>
            </div>
          </div>
        </div>
        <div class="col-12">
          <apexchart 
            class="accidentChart"
            ref="hourOcuurChart" 
            height="350" 
            type="line"
            :options="hourOcuurChart.chartOptions" 
            :series="hourOcuurChart.series"></apexchart>
        </div>
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="kindOcuurChart1" 
            height="350" 
            type="bar"
            :options="kindOcuurChart1.chartOptions" 
            :series="kindOcuurChart1.series"></apexchart>
        </div>
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="kindOcuurChart102" 
            height="350" 
            type="bar"
            :options="kindOcuurChart102.chartOptions" 
            :series="kindOcuurChart102.series"></apexchart>
        </div>
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="kindOcuurChart2" 
            height="350" 
            :options="kindOcuurChart2.chartOptions" 
            :series="kindOcuurChart2.series"></apexchart>
        </div>
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="ageOcuurChart" 
            height="350" 
            type="line"
            :options="ageOcuurChart.chartOptions" 
            :series="ageOcuurChart.series"></apexchart>
        </div>
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="personTypeOccurChart" 
            height="350" 
            type="bar"
            :options="personTypeOccurChart.chartOptions" 
            :series="personTypeOccurChart.series"></apexchart>
        </div>
        <div class="col-6">
            <apexchart 
              class="accidentChart"
              ref="mainAccChart3" 
              height="350" 
              type="bar"
              :options="mainAccChart3.chartOptions" 
              :series="mainAccChart3.series"></apexchart>
        </div>
        <div class="col-12">
            <apexchart 
              class="accidentChart"
              ref="mainAccChart32" 
              height="350" 
              type="bar"
              :options="mainAccChart32.chartOptions" 
              :series="mainAccChart32.series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'accident-status',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      fullscreen: false,
      colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
      searchParam: {
        plantCd: null,
        occurrenceDeptCd: '',
        accidentTypeCd: null,
        occurrenceLocation: '',
        longevity: 0,
      },
      yearOcuurChart: {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '년도별 안전사고 발생 건수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [],
        chartWidth: '80%',
      },
      hourOcuurChart: {
        chartOptions: {
          title: {
            text: '시간대별 안전사고 발생 건수'
          },
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          stroke: {
            width: [5, 7, 5],
            curve: 'straight',
            dashArray: [0, 8, 5]
          },
          xaxis: {
            categories: ['00시', '01시', '02시', '03시', '04시', '05시', '06시', '07시', '08시','09시','10시','11시','12시',
            '13시','14시','15시','16시','17시','18시','19시','20시','21시','22시','23시'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return   val + " 건"
              }
            }
          }
        },
        series: [
          {
            name: '시간',
            //24개
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        ],
        chartWidth: '80%',
      },
      seventDayOccurChart: {
        chartOptions: {
          title: {
            text: '요일별 안전사고 발생 건수'
          },
          colors: this.colors,
          labels: {
            style: {
              colors: this.colors,
              fontSize: '12px'
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [ '일', '월', '화', '수', '목', '금', '토'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [],
        chartWidth: '80%',
      },
      kindOcuurChart1: {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '발생형태별 발생 건수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + " 건"
              }
            }
          },
          legend: {
            position: 'top',
            horizontalAlign: 'center',
          }
        },
        series: [
          
        ],
        //  21, 12, 12, 15, 21, 12],
        chartWidth: '100%',
      },
      kindOcuurChart102: {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '재해유형별 발생 건수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + " 건"
              }
            }
          },
          legend: {
            position: 'top',
            horizontalAlign: 'center',
          }
        },
        series: [
          
        ],
        //  21, 12, 12, 15, 21, 12],
        chartWidth: '100%',
      },
      kindOcuurChart2: {
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
          },
          title: {
            text: '상해종류별 발생 건 수'
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: [],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
        series: [],
      },
      ageOcuurChart: {
        chartOptions: {
          title: {
            text: '연령대별 발생 건수'
          },
          colors: ['#f7f134', '#6bffe1', '#ffa35c','#ff5465',],
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          stroke: {
            width: [5, 7, 5],
            curve: 'straight',
            dashArray: [0, 8, 5]
          },
          xaxis: {
            categories: ['20대 미만', '20대', '30대', '40대', '50대', '60대 이상'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return   val + " 건"
              }
            }
          }
        },
        series: [
          {
            name: '시간',
            //24개
            data: [0, 0, 0, 0, 0, 0]
          }
        ],
        chartWidth: '80%',
      },
      personTypeOccurChart: {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '정규직/임시직 발생 건 수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['00E396', 'transparent']
          },
          colors: ['#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: ['정규직', '임시직'],
          },
          yaxis: {
            title: {
              text: undefined
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + " 건"
              }
            }
          },
          legend: {
            position: 'top',
            horizontalAlign: 'center',
          }
        },
        series: [
          
        ],
        //  21, 12, 12, 15, 21, 12],
        chartWidth: '100%',
      },
      mainAccChart3: { 
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '팀별 발생 건수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + " 건"
              }
            }
          },
          legend: {
            show: false
          }
        },
        series: [
          
        ],
        //  21, 12, 12, 15, 21, 12],
        chartWidth: '100%',
      },
      mainAccChart32: { 
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '반별 발생 건수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + " 건"
              }
            }
          },
          legend: {
            show: false
          }
        },
        series: [
          
        ],
        //  21, 12, 12, 15, 21, 12],
        chartWidth: '100%',
      },
      period: [],
      options: [],
      selectProcessCd: '',
      editable: true,
      searchUrl: '',
      count: 0,
      yearUrl: '',
      hourUrl: '',
      sevendayUrl: '',
      kindUrl: '',
      ageUrl: '',
      personType: '',
      AccGraph3Url: '',
      AccGraph32Url: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.yearUrl = selectConfig.sop.iim.accident.status.year.url;
      this.hourUrl = selectConfig.sop.iim.accident.status.hour.url;
      this.sevendayUrl = selectConfig.sop.iim.accident.status.season.url;
      this.kindUrl1 = selectConfig.sop.iim.accident.status.kind1.url;
      this.kindUrl102 = selectConfig.sop.iim.accident.status.kind102.url;
      this.kindUrl2 = selectConfig.sop.iim.accident.status.kind2.url;
      this.ageUrl = selectConfig.sop.iim.accident.status.age.url;
      this.personType = selectConfig.sop.iim.accident.status.personType.url;
      this.AccGraph3Url = selectConfig.sop.iim.accident.status.dept1.url;
      this.AccGraph32Url = selectConfig.sop.iim.accident.status.dept2.url;
      // 년도 설정
      // let years = [];
      // for (let i = 0; i < 5; i++) {
      //   years.splice(0, 0, this.$comm.getPrevYear(i));
      // }
      // this.yearOcuurChart.chartOptions.xaxis.categories = years;

      // url setting
      this.getYearList();
      this.getHourList();
      this.getSevenDayList();
      this.getKindList1();
      this.getKindList102();
      this.getKindList2();
      this.getAgeList();
      this.getPersonTypeList();
      this.getAccGraph3();
      this.getAccGraph32();
    },
    getYearList() {
      if(this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.yearUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {

        this.yearOcuurChart.chartOptions.xaxis.categories = this.$_.map(_result.data, 'occurYear');
        this.yearOcuurChart.series = [{data: this.$_.map(_result.data, 'yearCount'), name: '재해 건 수'}];
      
        this.$refs['yearOcuurChart'].refresh();
        this.yearOcuurChart.chartWidth = '90%';
      },);
    },
    getHourList() {
      this.hourOcuurChart.series[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.$http.url = this.hourUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {

        this.$_.forEach(_result.data, _item => {
        // 년도에 따른 idx를 구하여 series.data의 위치 통일
          let idx = this.hourOcuurChart.chartOptions.xaxis.categories.indexOf(_item.occurTime)

          this.hourOcuurChart.series[0].data[idx] = _item.timeCount
        })
        this.hourOcuurChart.chartWidth = '90%';
        this.$refs['hourOcuurChart'].refresh();
      },);
    },
    getSevenDayList() {
      this.$http.url = this.sevendayUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.seventDayOccurChart.chartOptions.xaxis.categories = this.$_.map(_result.data, 'occurDay');
        this.seventDayOccurChart.series = [{data: this.$_.map(_result.data, 'dayCount'), name: '재해 건 수'}];

        this.$refs['seventDayOccurChart'].refresh();
        this.seventDayOccurChart.chartWidth = '90%';
      },);
    },
    getKindList1() {
      this.$http.url = this.kindUrl1;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.kindOcuurChart1.chartOptions.xaxis.categories = this.$_.map(_result.data, 'accidentTypeName');
        this.kindOcuurChart1.series = [{data: this.$_.map(_result.data, 'typeCount'), name: '재해 건 수'}];
        
        this.$refs['kindOcuurChart1'].refresh();
        this.kindOcuurChart1.chartWidth = '90%';
      },);
    },
    getKindList102() {
      this.$http.url = this.kindUrl102;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.kindOcuurChart102.chartOptions.xaxis.categories = this.$_.map(_result.data, 'accidentTypeName');
        this.kindOcuurChart102.series = [{data: this.$_.map(_result.data, 'typeCount'), name: '재해 건 수'}];
        
        this.$refs['kindOcuurChart102'].refresh();
        this.kindOcuurChart102.chartWidth = '90%';
      },);
    },
    getKindList2() {
      this.$http.url = this.kindUrl2;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.kindOcuurChart2.chartOptions.labels = this.$_.map(_result.data, 'woundName');
        this.kindOcuurChart2.series = this.$_.map(_result.data, 'woundCount');
        
        this.$refs['kindOcuurChart2'].refresh();
        this.kindOcuurChart2.chartWidth = '90%';
      },);
    },
    getAgeList() {
      this.$http.url = this.ageUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (_result.data && _result.data.length > 0 && _result.data[0]) {
          this.ageOcuurChart.series = [
            {
              data: [_result.data[0].tenAge,_result.data[0].twentyAge, _result.data[0].thirtyAge, 
                    _result.data[0].fourtyAge, _result.data[0].fiftyAge, _result.data[0].sixtyAge
              ],
              name: '재해 건 수'
            }
          ];
        } else {
          this.ageOcuurChart.series = [
            {
              data: [0,0,0,0,0,0],
              name: '재해 건 수'
            }
          ]
        }
        
        this.$refs['ageOcuurChart'].refresh();
        this.ageOcuurChart.chartWidth = '90%';
      },);
    },
    getPersonTypeList() {
      this.$http.url = this.personType;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (_result.data && _result.data.length > 0 && _result.data[0]) {
          this.personTypeOccurChart.series = [
            {
              data: [_result.data[0].regul, _result.data[0].noRegul],
              name: '재해 건 수'
            }
          ];
        } else {
          this.personTypeOccurChart.series = [
            {
              data: [0,0],
              name: '재해 건 수'
            }
          ]
        }
        
        this.$refs['personTypeOccurChart'].refresh();
        this.personTypeOccurChart.chartWidth = '90%';
      },);
    },
    getAccGraph3() {
      this.$http.url = this.AccGraph3Url;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.mainAccChart3.chartOptions.xaxis.categories = this.$_.map(_result.data, 'occurrenceDeptName');
        this.mainAccChart3.series = [{data: this.$_.map(_result.data, 'deptCount'), name: '재해 건 수'}];
        
        this.$refs['mainAccChart3'].refresh();
        this.mainAccChart3.chartWidth = '90%';
      },);
    },
    getAccGraph32() {
      this.$http.url = this.AccGraph32Url;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.mainAccChart32.chartOptions.xaxis.categories = this.$_.map(_result.data, 'occurrenceDeptName');
        this.mainAccChart32.series = [{data: this.$_.map(_result.data, 'deptCount'), name: '재해 건 수'}];
        
        this.$refs['mainAccChart32'].refresh();
        this.mainAccChart32.chartWidth = '90%';
      },);
    },
    plantChange() {
      if(this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.getYearList();
      this.getHourList();
      this.getSevenDayList();
      this.getKindList1();
      this.getKindList102();
      this.getKindList2();
      this.getAgeList();
      this.getPersonTypeList();
      this.getAccGraph3();
      this.getAccGraph32();
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important

// .el-cascader-node
//   padding: 0 30px 0 5px !important
// .el-cascader-menu
//   min-width: auto
// .el-cascader-node__label
//   padding: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  // border-right: #dee2e6 solid 0 !important
  // border-bottom: #dee2e6 solid 1px !important
  padding: 0px !important
.widget-yearOcuurChart
  padding: 5px !important
.widget-yearOcuurChart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important

.accidentChart
  padding: 20px !important
  border: 1px solid #dee2e6
  border-top: 3px solid #dee2e6
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>